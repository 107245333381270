import { Component, OnInit } from '@angular/core';
import { AppStateService } from '../app-state.service';
@Component({
  selector: 'app-our-story',
  templateUrl: './our-story.component.html',
  styleUrls: ['./our-story.component.scss']
})

export class OurStoryComponent implements OnInit 
{
  public isMobile: boolean;

  constructor(private appStateService: AppStateService)
  {
    this.isMobile = appStateService.getIsMobile();
  }

  ngOnInit()
  {
    this.isMobile = this.appStateService.getIsMobile();
  }

}
