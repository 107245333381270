import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Guest } from '../models/guest.model';
import { Person } from '../models/Person.model';


@Injectable({
  providedIn: 'root'
})
export class RsvpServiceService 
{
  
  constructor(private firestore: AngularFirestore) { }

  public createRSVP(rsvp: Guest)
  {
    return new Promise<any>((resolve, reject) => 
    {
      this.firestore.collection("Attendees").add(
        {
          firstName: rsvp.fname,
          lastName:  rsvp.lname,
          relatedGuests:     rsvp.relatedPeople,
          isGlutenFree: false,
          isVegetarian: false,
          isVegan:      false,
          hasPlusOne:   rsvp.plusOne,
          plusOneAttending: false,
          isAttending:      false,
          plusOneGlutenFree: false,
          plusOneVegetarian: false,
          plusOneVegan:      false,
          email:  ""
        }
      ).then(success => {
         alert("RSVPed: "+rsvp.fname + ' ' + rsvp.lname)}, error => alert(error));
    });
  }

  public getGuests(): Observable<any[]>
  {
    return this.firestore.collection("Attendees").valueChanges();
  }

  public updateRSVP(pPerson: any): Promise<any>
  {
    let vPromise = new Promise<boolean>((resolve, reject) => 
    {
      this.firestore.collection("Attendees").get().subscribe((query)=>
      {
        query.docs.forEach((doc) =>
        {
          let vDocData: any = doc.data();
          if(vDocData.firstName + ' ' + vDocData.lastName == pPerson.name )
          {
            let vAttending: boolean = false;
            if(pPerson.isAttending == '1')
            {
              vAttending = true;
            }

            this.firestore.collection("Attendees").doc(doc.id).update(
              {
                isGlutenFree: pPerson.isGlutenFree,
                isVegetarian: pPerson.isVegetarian,
                isVegan:      pPerson.isVegan,
                plusOneAttending: pPerson.isBringingPlusOne,
                plusOneVegan: pPerson.plusOneVegan,
                plusOneVegetarian: pPerson.plusOneVegetarian,
                plusOneGlutenFree: pPerson.plusOneGlutenFree,
                isAttending:      vAttending,
                email: pPerson.email
              }
            ).then(success =>
              {
                resolve(true);
              },
              error =>
              { 
                resolve(false);
                
              });
          }
        })
      })
    });
    return vPromise;
   }

   public getAllAttendees(): Promise<Person[]>
   {
    let vPromise = new Promise<Person[]>((resolve, reject) => {
      this.firestore.collection("Attendees", ref => ref.where('isAttending', '==', true)).get().subscribe((query) => 
      {
        let vGuests: Person[] = [];
        let vIndex:  number  = 0;
        query.docs.forEach((doc) =>
        {
          let vDocData: any = doc.data();
          let vGuest:   Person = new Person(vDocData.firstName, vDocData.lastName, vDocData.relatedGuests, vDocData.isAttending, vDocData.hasPlusOne, vDocData.plusOneAttending, vDocData.isVegetarian, vDocData.isVegan, vDocData.isGlutenFree, vDocData.plusOneGlutenFree, vDocData.plusOneVegetarian, vDocData.plusOneVegan, vDocData.email);
          vGuests.push(vGuest);
        })
        resolve(vGuests);
      })
    });
    
   return vPromise;
   }
}
