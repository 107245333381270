import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RegistryComponent } from './registry/registry.component';
import { WeddingDetailsComponent } from './wedding-details/wedding-details.component';
import { RsvpComponent } from './rsvp/rsvp.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { PhotosComponent } from './photos/photos.component';
import { RsvpAdderComponent } from './rsvp-adder/rsvp-adder.component';
import { AttendeesComponent } from './attendees/attendees.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'attendees', component: AttendeesComponent},
  { path: 'registry', component: RegistryComponent},
  { path: 'details', component: WeddingDetailsComponent},
  { path: 'photos', component: PhotosComponent},
  { path: 'rsvp',    component: RsvpComponent},
  { path: 'us',    component: OurStoryComponent},
  { path: 'rachel-rsvp-adder-component-superboat-1912', component: RsvpAdderComponent},
  { path: '', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
