import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { Observable } from 'rxjs';
import { Guest } from '../models/guest.model';
import { RsvpServiceService } from '../services/rsvp-service.service';
import { MatStepper } from '@angular/material';
import { Person } from '../models/Person.model';


@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.scss']
})


export class RsvpComponent implements OnInit 
{
  rsvpForm:          FormGroup = null;
  plusOneForm:       Set<FormGroup> = null;
  isBringingPlusOne: boolean   = false;
  guests$:           Observable<any> = this.rsvpService.getGuests();
  guestsMap:         Map<string, Person>  = new Map();
  guestsToRSVP:      Map<string, string>  = new Map();
  guestsToRSVPForm:  FormGroup            = null;
  isDone:            boolean              = false;
  constructor(private rsvpService: RsvpServiceService,
              private formBuilder: FormBuilder) { }

  ngOnInit() 
  {
    this.guests$.subscribe((pGuests) =>
    {
      pGuests.forEach(pGuest => 
      {
        let personName: string = pGuest.firstName + ' ' + pGuest.lastName;
        let vGuest:     Person = new Person(null, null,null,null,null,null,null,null,null,null,null,null,null);
        vGuest.fname = pGuest.firstName;
        vGuest.lname = pGuest.lastName;
        vGuest.isAttending    = pGuest.isAttending;
        vGuest.relatedPeople = pGuest.relatedGuests;
        vGuest.isBringingPlusOne = pGuest.plusOneAttending;
        vGuest.hasPlusOne        = pGuest.hasPlusOne;
        vGuest.isGlutenFree      = pGuest.isGlutenFree;
        vGuest.isVegan           = pGuest.isVegan;
        vGuest.isVegetarian      = pGuest.isVegetarian;
        vGuest.plusOneGlutenFree = pGuest.plusOneGlutenFree;
        vGuest.plusOneVegetarian = pGuest.plusOneVegetarian;
        vGuest.plusOneVegan      = pGuest.plusOneVegan;
        vGuest.email             = pGuest.email;
        this.guestsMap.set(personName, vGuest);
      });
    })
    this.rsvpForm = new FormGroup(
      {
        name:         new FormControl()
      }
    );

    this.guestsToRSVPForm = this.formBuilder.group({
      people: this.formBuilder.array([
        this.createPeopleFormGroup('', null)
      ])
    });

  }

  public loadPerson(stepper: MatStepper)
  {
    if(this.guestsMap.has(this.rsvpForm.value.name))
    {
      this.guestsToRSVPForm = this.formBuilder.group({
        people: this.formBuilder.array([
          this.createPeopleFormGroup(this.rsvpForm.value.name, this.guestsMap.get(this.rsvpForm.value.name))
        ])
      });
      this.guestsToRSVP.set(this.rsvpForm.value.name, 'Pending');
      this.guestsMap.get(this.rsvpForm.value.name).relatedPeople.forEach((vPerson, vIndex) => 
      {
        this.guestsToRSVP.set(vPerson, 'Pending');
        this.addPeopleToFormGroup(vPerson, this.guestsMap.get(vPerson));
      })
      stepper.next();
    }
    else
    {
      this.guestsToRSVPForm = this.formBuilder.group({
        people: this.formBuilder.array([
          this.createPeopleFormGroup('', null)
        ])
      });

      alert('No name found! Please enter your full name!')
    }
  }

  private createPeopleFormGroup(pName: string, pPerson: Person): FormGroup
  {
    if(pPerson == null)
    {
      return new FormGroup({
        'name': new FormControl(pName),
        'isAttending': new FormControl('0'),
        'isBringingPlusOne': new FormControl(),
        'hasPlusOne': new FormControl(),
        'isVegan': new FormControl(),
        'isVegetarian': new FormControl(),
        'isGlutenFree': new FormControl(),
        'registered'  : new FormControl('Pending'),
        'plusOneGlutenFree': new FormControl(),
        'plusOneVegetarian': new FormControl(),
        'plusOneVegan': new FormControl(),
        'email': new FormControl()
      })
    }
    if(pPerson.isAttending)
    {
      return new FormGroup({
        'name': new FormControl(pName),
        'isAttending': new FormControl('1'),
        'isBringingPlusOne': new FormControl(pPerson.isBringingPlusOne),
        'hasPlusOne': new FormControl(pPerson.hasPlusOne),
        'isVegan': new FormControl(pPerson.isVegan),
        'isVegetarian': new FormControl(pPerson.isVegetarian),
        'isGlutenFree': new FormControl(pPerson.isGlutenFree),
        'registered'  : new FormControl('Pending'),
        'plusOneGlutenFree': new FormControl(pPerson.plusOneGlutenFree),
        'plusOneVegetarian': new FormControl(pPerson.plusOneVegetarian),
        'plusOneVegan': new FormControl(pPerson.plusOneVegan),
        'email': new FormControl(pPerson.email)
      })
    }
    else
    {
      return new FormGroup({
        'name': new FormControl(pName),
        'isAttending': new FormControl('0'),
        'isBringingPlusOne': new FormControl(pPerson.isBringingPlusOne),
        'hasPlusOne': new FormControl(pPerson.hasPlusOne),
        'isVegan': new FormControl(pPerson.isVegan),
        'isVegetarian': new FormControl(pPerson.isVegetarian),
        'isGlutenFree': new FormControl(pPerson.isGlutenFree),
        'registered'  : new FormControl('Pending'),
        'plusOneGlutenFree': new FormControl(pPerson.plusOneGlutenFree),
        'plusOneVegetarian': new FormControl(pPerson.plusOneVegetarian),
        'plusOneVegan': new FormControl(pPerson.plusOneVegan),
        'email': new FormControl(pPerson.email)
      })
    }
  }

  private addPeopleToFormGroup(pName: string, pPerson: Person)
  {
    let people: FormArray = this.guestsToRSVPForm.get('people') as FormArray;
    people.push(this.createPeopleFormGroup(pName, pPerson));
  }

  public checkForm(stepper: MatStepper)
  {
    stepper.selected.completed = true;
    
    stepper.next();
    let vPeople: FormArray = this.guestsToRSVPForm.get('people') as FormArray;
    vPeople.value.forEach(pPerson => 
    {
      let vRegistered: Promise<any> = this.rsvpService.updateRSVP(pPerson);
      vRegistered.then(pRegistered =>
      {
        if(pRegistered == true)
        {
          this.guestsToRSVP.set(pPerson.name, 'Success');
        }
        else
        {
          
          this.guestsToRSVP.set(pPerson.name, 'Failure');
        }
      });
      
    });

    this.isDone = true;
  }

  public formSubmit()
  {
    if(this.guestsMap.has(this.rsvpForm.value.firstName))
    {
     
    }
    this.rsvpService.updateRSVP('test');
   /* let formValue: any;
    let rsvp:      Guest  = new Guest();
    let regex:     RegExp = new RegExp(/\S+@\S+\.\S+/);
    formValue = this.rsvpForm.value;

    if(formValue.firstName == null || formValue.lastName == null || formValue.email == null)
    {
      alert('Please check form, missing data.')
      return;
    }
    if(!regex.test(formValue.email))
    {
      alert('Please enter a valid Email');
      return;
    }
  
    if(formValue.plusOneFirstName != null && formValue.plusOneLastName == null || formValue.plusOneFirstName == null && formValue.plusOneLastName != null)
    {
      alert('Please check form, missing data.')
      return;
    }


    rsvp.fname = formValue.firstName;
    rsvp.lname = formValue.lastName;
    rsvp.email = formValue.email;

    if(formValue.isGlutenFree)
    {
      rsvp.glutenFree = true;
    }
    else
    {
      rsvp.glutenFree = false;
    }

    if(formValue.isVegatarian)
    {
      rsvp.vegetarian = true;
    }
    else
    {
      rsvp.vegetarian = false;
    }

    if(formValue.isVegan)
    {
      rsvp.vegan = true;
    }
    else
    {
      rsvp.vegan = false;
    }

    if(formValue.plusOneFirstName != null)
    {
      let plusOne: Guest = new Guest();

      plusOne.fname = formValue.plusOneFirstName;
      plusOne.lname = formValue.plusOneLastName;

      if(formValue.plusOneGlutenFree)
      {
        plusOne.glutenFree = true;
      }
      else
      {
        plusOne.glutenFree = false;
      }

      if(formValue.plusOneVegetarian)
      {
        plusOne.vegetarian = true;
      }
      else
      {
        plusOne.vegetarian = false;
      }

      if(formValue.plusOneVegan)
      {
        plusOne.vegan = true;
      }
      else
      {
        plusOne.vegan = false;
      }
      plusOne.email = null;
      rsvp.plusOne = plusOne;
    }
    else
    {
      rsvp.plusOne = null;
    }
    
    this.rsvpService.createRSVP(rsvp);*/
  }
}
