
export class Person
{
  public fname: string;
  public lname: string;
  public relatedPeople: string[];
  public isAttending:   boolean;
  public hasPlusOne:    boolean;
  public isBringingPlusOne: boolean;
  public isVegetarian: boolean;
  public isVegan:      boolean;
  public isGlutenFree: boolean;
  public plusOneGlutenFree: boolean;
  public plusOneVegetarian: boolean;
  public plusOneVegan: boolean;
  public email: string;

  constructor(fname, lname, relatedPeople, isAttending, hasPlusOne, isBringingPlusOne, isVegetarian, isVegan, isGlutenFree, plusOneGlutenFree, plusOneVegetarian, plusOneVegan, email)
  {
    this.fname = fname;
    this.lname = lname;
    this.relatedPeople = relatedPeople;
    this.isAttending = isAttending;
    this.hasPlusOne = hasPlusOne;
    this.isBringingPlusOne = isBringingPlusOne;
    this.isVegetarian = isVegetarian;
    this.isVegan      = isVegan;
    this.isGlutenFree = isGlutenFree;
    this.plusOneGlutenFree = plusOneGlutenFree;
    this.plusOneVegan   = plusOneVegan;
    this.plusOneVegetarian = plusOneVegetarian;
    this.email = email;
  }
}