import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData
{
  name: string;
  URL:  string;
}

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit
{
  public photos: DialogData[] = 
  [
    { name: 'Sept 2017: Outside the T', URL: 'https://lh3.googleusercontent.com/fHd6EmshCRGWcl0EG2BEPz90buaxMsZzFBzlw6mtnHu7DSWZ2xDrRlVigoyl6L6fc4G5Ja-GBiV2gGiNeDxKAcjSUqmp95em-dT-XGv-QmPx1P_k3yAI7sMGkuvU3VGa92d193hCEg=w2400' },
    { name: 'Feb 2018: Monster Jam', URL: 'https://lh3.googleusercontent.com/GAjNEPdzVPgY5XyqYYtQl6-ahBxjVaVEqdc7TE65H69DVPvZ_TH_3ZR0lFqCXck4a9rLfA3XlwGtuzSo5c1eVh_XoHdTgU8GMJhdHFz2C4LMV0PC5Df9kBMYYgUSO5WwcMNztCIUNQ=w2400' },
    { name: 'Mar 2018: Niagara Falls', URL: 'https://lh3.googleusercontent.com/Fh0bCNrRDlJANLMdWpIaqXbxv5kIeCsJ3ylqPyGd70kIXngwsUU1OOhYmTQmbNmT30oHYMMXLZ3cD8wnVJxjKb5_lP_7jSNn9MAM0wORLvb02ncOgr7vhRgJ3uI-9UFqscAb3Fr9-g=w2400' },
    { name: 'Mar 2018: Syracuse Zoo', URL: 'https://lh3.googleusercontent.com/VAXkyUu3FSiX4LYDqW7qP56A1LBx0Jhz0eMkbTKafPzPgazuVh5CvkEylNBTiqCOPlD9rg6XJg9Ep57XD3PXti-wcV-qed63Jor15YVInZEHov1cYwsQDJEga2iVTVN9dgcEqcXzLQ=w2400' },
    { name: 'Mar 2018: Syracuse Zoo', URL: 'https://lh3.googleusercontent.com/GET9n5D2nbBdbAXMS24lx5kvzIySFfGKBxXP9fr8x85ir7xYyz-3hD2M8uloOytOf-uxWXcephd0RcSNh6HhKFRa4uO5--DEFxcGZ-KrouLZqvTuml7EP9u6ZGDjryjbbMZEEwufgg=w2400' },
    { name: 'June 2018: Ontario', URL: 'https://lh3.googleusercontent.com/N0YyUgcRTKb63c98_AZiloiwRmH7YcEUAdfwyhqX5AE_utzy_ZYZG3FHq9ZkYfAg9FfSOF5p4C3sQot-79NFuRrtM_ew-7FuHy03dkOQFtXlYluP8kHsdfcAIGibnNoE6nT3zL19EQ=w2400' },
    { name: 'June 2018: Niagara Falls', URL: 'https://lh3.googleusercontent.com/Y5f6E5ARTss89AABrYmLjNAHGSl348Jg7Dw94iVXK6GG_4VSZs6o_G8Sz0ZsD92LPfsqJpyluBTnSIWezjgH2Wn-t1hBnHhOLO06ogUEgkxOnd1z9L08lndflypOSIjvdBhQCDCbjQ=w2400' },
    { name: 'Aug 2017: First Date', URL: 'https://lh3.googleusercontent.com/M5tY_Xao12KytaK-cSY306DECSRjdli5NCKevdRHbtuq0bHZa9X-RuIBZTRJFCEPSVSijWpCHVvkv6gBtrWjjc3O0R9Xibl1q0dTKjN_lWJwHx3cpIzxRW4YkvM4SM6g8xseB7Tu5g=w2400' },
    { name: 'Jul 2017: Syracuse Boy', URL: 'https://lh3.googleusercontent.com/SCFVsgbJg_OdhmrVjZf_lU-7hzdJGqY2zIBzI0s948pGYJCplZRIJsv_K-bMTJ-R-mVRDCjxLpQP_mJj3vX3aYwRLeO8SRHbPkla87i_Sg0VlMNpOuKvLGQea8_kGX6N_z80PeCvbQ=w2400' },
    { name: 'Dec 2018: Simeone Foundation Auto Museum', URL: 'https://lh3.googleusercontent.com/ZjBv2K-oJkn6qOZK-wMfJNwpzSC0CS6BJysHxxbiaazbpznX-VsCytFf-5mIqzQPTBPMeprrcIK2doDM2veysOpF1qY_vs-1iS_voTCzybIevOIH99n0E6meza90Bn0Jri3kJ4XPEw=w2400' },
    { name: 'Oct 2018: Corn Maze', URL: 'https://lh3.googleusercontent.com/YXtRcfML2aEyO0i-wyHhVBLckTdmjH8wm83vzZidvjTMeRYGBQwkATFLsfVEXxXGQmUMUzUIacp_HJ1ROtwJTNcF97Wa-TZnVz4GYa8HOovVo3_EvDCMkxZeXQbpUpUHv0s8v9K6Eg=w2400' },
    { name: 'Jan 2019: Pawn Shop', URL: 'https://lh3.googleusercontent.com/OTQHZe4bDeTJDUgjRNrN2HQMYlFxGOaKl-sDOEGWQxGNbTDKSR2H8PxMSwxx6y4Ul750rwSvI1_-CjOgG6Ch42Ef1gkiE9qdt4kA1zeE71HpMFsIB2WRolPp7AI0wslfLf3DmU_G8w=w2400' },
    { name: 'Feb 2019: Monster Jam', URL: 'https://lh3.googleusercontent.com/PHtcIVIvVFs2CXgrM-2c8ug9GD0EFuLpsCmBaVjuhVi98adCA-9dvF306BjuGD9_2pufMII9_YXDC3pT02i2KEBC_k8vMdALnJ7zhgAuA3aTwky-ccVT_GEszjzk7uoS28cqFJV_zg=w2400' },
    { name: 'Apr 2019: Shady Maple', URL: 'https://lh3.googleusercontent.com/U7-t_O6E3zCK2y7FFewTOPqXiNJuWZ8li090PpW1azGf678SrVwhHw183No2aORWLUOkzzr5d1vNpruQ0CI_6exh7y9gqYLfkW3_4R_qEeoHLqswRh1iQOZn9pKAX1WCeRlvL6c4aA=w2400' },
    { name: 'May 2019: Falling Water', URL: 'https://lh3.googleusercontent.com/dMcAfSZa97pEhcfeJvEznUI0MsocheCBV9-ZvWU1ffHyfnlQXNbyZYYditny2qNME2i1NA8VTTgk9wcQKN6dAZxxEOvMFr7MRKBqoTkR3DZJTuCpxVsnEFmKAyW0lhLKPfVaxjLEZg=w2400' },
    { name: 'July 2019: The Morrisons Wedding', URL: 'https://lh3.googleusercontent.com/F6gFf4jYG00nrjt1mIlGTlANoKoV8219DKHXcPIbRCIlRYrhS3npFGvJpnbb8zVx9kzFHvjDRIIkTgZW1YRNEJUfu7HwQXimfoZtY8KNqamUCRyOD_Fw99IeXBpgx4eFC0X9BZFaZQ=w2400' },
    { name: 'Aug 2019: The Great NYS Fair', URL: 'https://lh3.googleusercontent.com/gmkRUOm-C2uiCTIa8eQ0Q5iOE6DOkCsrlZKRd73AyfSEUoekCrvUWI7HeOOO5TWqdGvb4Qe-GQIuP45LPANQQTIpVXVTa9onnx1Y67LF6uvBfuisiOU10XosQ9I3TEa0dFe1jFPEKg=w2400' },
    { name: 'Sep 2019: Fudge Farm', URL: 'https://lh3.googleusercontent.com/TN7fxUUR3GaFi5XpkrhEWAt6udHvlHd1yBV8x1Ua-l5fmMRNmo6zfsaN7D4k7LjOeOS8xTRPQYCyHGQ_qIk2JhfKVH1HUHAgkEr8H_4RWO0iBuWI5JihxZvSwzRcHcXkYrJWkucjQg=w2400' },
    { name: 'Oct 2019: Klavons', URL: 'https://lh3.googleusercontent.com/VRAN0v6Dmvb05-DY0v5JxRIgXvnmHKvksRtabSJ2fJT_hM8qi_qzxnaHKBZG9TO1ri-0LyqZoq1Xse_BlIHnxNXw6JoCzaEtos_y5cAL57h8bw9Itb_Uep4z_2hZ6tExibBvJUTLbA=w2400' },
    { name: 'Oct 2019: Corn Maze and Donuts', URL: 'https://lh3.googleusercontent.com/KU1QincqCmg7lkCX53qQkzVqStuC8J2DnKtDfcGnbYzUOVSHDVsZ2z78mnYglur5CPZieIW_iA32liAWGUf4aUDeNtuVh57d5lQi2oRiBbi4xcnvEj4YO0Ip2NfmqpW2ntTT-ewAZw=w2400' },
    { name: 'Dec 2019: Philly', URL: 'https://lh3.googleusercontent.com/kTlK3LzwkYcUae6vW3vubyUj-XLg4IkLQ-_ODdECKMyD0NVrL6X0mL-XJo4rAOPmeebQyR7wGHxUS5jsh3GcBl3lRJB3E_aPCzkdLMQCLRGDHwvSNNV9eE0Y1a8uYZV87woVs_KyJQ=w2400' },
    { name: 'Apr 2020: Quarantine Lifts', URL: 'https://lh3.googleusercontent.com/qpy7h7BqJGclC7bOprrBdmWJjBG4ssyg-39mPzJyNBZLKmNWzLnUEo3dKCDYXGPeiJtRVMKlazsm68T21lUtyZFy8BKJPNSqySnkWIXH9s_kVIIErolvpK0orzm6ofkqefPqJ3swVA=w2400' },
    { name: 'May 2020: Cathedral of Learning', URL: 'https://lh3.googleusercontent.com/au64nzajd_Qtkc2xLv6w02ja-HGx8s1SSqtDhDqol2rpM8awkrpulAQGrf55KzT_3J4wXh-N1n9PbVBmxEIROZKNeeNz-uAP4Z9eIf6gl934QLBvQPanhZUHXktdazEqsgieKHzxeQ=w2400' },
    { name: 'May 2020: Herrs Island', URL: 'https://lh3.googleusercontent.com/e32625S2tYb2B7DpPz47mKxBx4DGlKrXFicpwvIZ45WSkotT3ALZW7D5TQOO7uhJ1FEXPWwxevGTkGhLTdbxEwD-ezYkq5nIYjXqk9hYOyRhs7-K1TBIYIDwdQweuBKezzK6o_gLmA=w2400' },
  ];

  ngOnInit() 
  {
  }

  constructor(public dialog: MatDialog) 
  { 

  }
  openDialog(pURL: string): void
  {
    const dialogRef = this.dialog.open(PhotosDialog, {
      data: {URL: pURL}
    });
  }
}

@Component({
  selector: 'photos-dialog',
  templateUrl: 'photos-dialog.html',
})
export class PhotosDialog
{
  constructor(public dialogRef: MatDialogRef<PhotosDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData)
  {

  }

  onNoClick(): void 
  {
    this.dialogRef.close();
  }
}