import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit 
{
  public isMenuOpen:  boolean = false;
  public currentPage: String = "Home";

  constructor(private location: Location) 
  { 

  }

  ngOnInit() 
  {
    if(this.location.path() == '/home')
    {
      this.currentPage = 'Home';
    }
    else if(this.location.path() == '/details')
    {
      this.currentPage = 'Wedding Details';
    }
    if(this.location.path() == '/us')
    {
      this.currentPage = 'Our Story';
    }
    if(this.location.path() == '/photos')
    {
      this.currentPage = 'Photos';
    }
    if(this.location.path() == '/rsvp')
    {
      this.currentPage = 'RSVP'; 
    }
    if(this.location.path() == '/registry')
    {
      this.currentPage = 'Registry';
    }
  }

  toggleSideNav()
  {
    this.isMenuOpen = !this.isMenuOpen;
  }

  buttonClick(pString: String)
  {
    this.currentPage = pString;
    this.toggleSideNav();
  }
}
