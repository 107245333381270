import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { MatToolbarModule, MatGridListModule, MatButtonModule, MatIconModule, MatSidenavModule, MatCardModule, MatTabsModule, MatDialogModule, MatFormFieldModule, MatCheckboxModule, MatInputModule, MatExpansionModule, MatStepperModule, MatButtonToggleModule, MatRadioModule, MatDividerModule } from '@angular/material';
import { RegistryComponent } from './registry/registry.component';
import { HomeComponent } from './home/home.component';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav/side-nav.component';
import { WeddingDetailsComponent } from './wedding-details/wedding-details.component';
import { RsvpComponent } from './rsvp/rsvp.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { PhotosComponent, PhotosDialog } from './photos/photos.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RsvpAdderComponent } from './rsvp-adder/rsvp-adder.component';
import { AttendeesComponent } from './attendees/attendees.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    RegistryComponent,
    HomeComponent,
    SideNavComponent,
    WeddingDetailsComponent,
    RsvpComponent,
    ContactUsComponent,
    OurStoryComponent,
    PhotosComponent,
    PhotosDialog,
    RsvpAdderComponent,
    AttendeesComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatGridListModule, 
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatCardModule,
    MatTabsModule,
    MatDialogModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDxXsjFuI77LJHywpd14LXCyeoDO-MrCkE'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatExpansionModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatDividerModule
  ],
  providers: [AngularFirestore],
  bootstrap: [AppComponent],
  entryComponents: [
    PhotosDialog
  ]
})
export class AppModule { }
