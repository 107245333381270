import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Location } from '@angular/common';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  animations: [
    trigger('buttonPress', [
      state('state1', style({
        margin:   '0',
        position: 'absolute',
        top:      '45%',
        width:    '100%'
      })),
      state('state2', style({
        margin:   '0',
        position: 'absolute',
        top:      '0%',
        width:    '100%'
      })),
      transition('state1=>state2', animate('500ms'))
    ])
  ]
})
export class NavBarComponent implements OnInit 
{
  public currentState = 'state1';
  public isBlack      = false;

  constructor(private location: Location) 
  { 
  }

  ngOnInit()
  {
   if(this.location.path() == '/home')
   {
     this.currentState = 'state1';
     this.isBlack      = false;
   }
   else if(this.location.path() == '/photos' || this.location.path() == '/attendees')
   {
     this.currentState = 'state2';
     this.isBlack      = true;
   }
   else
   {
     this.currentState = 'state2';
     this.isBlack      = false;
   }
  }

  changeState(pState: string)
  {
    this.currentState = 'state2';

    if (pState === 'photos')
    {
      this.isBlack = true;
    }
    else
    {
      this.isBlack = false;
    }
  }

}
