import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { Observable } from 'rxjs';
import { Guest } from '../models/guest.model';
import { RsvpServiceService } from '../services/rsvp-service.service';
import { map } from 'rxjs/operators';


export class Person
{
  public fname: string;
  public lname: string;
  public relatedPeople: string[];
}

@Component({
  selector: 'app-rsvp-adder',
  templateUrl: './rsvp-adder.component.html',
  styleUrls: ['./rsvp-adder.component.scss']
})
export class RsvpAdderComponent implements OnInit 
{
  rsvpForm:          FormGroup = null;
  plusOneForm:       Set<FormGroup> = null;
  isBringingPlusOne: boolean   = false;
  
  constructor(private rsvpService: RsvpServiceService) { }

  ngOnInit() 
  {
    
    this.rsvpForm = new FormGroup(
      {
        firstName:         new FormControl(),
        lastName:          new FormControl(),
        hasPlusOne:          new FormControl(),
        plus1FirstName:   new FormControl(),
        plus1LastName:   new FormControl(),
        guest1FirstName:   new FormControl(),
        guest1LastName:   new FormControl(),
        guest1PlusOne:  new FormControl(),
        guest2FirstName:   new FormControl(),
        guest2LastName:   new FormControl(),
        guest2PlusOne:  new FormControl(),
        guest3FirstName:   new FormControl(),
        guest3LastName:   new FormControl(),
        guest3PlusOne:  new FormControl(),
        guest4FirstName:   new FormControl(),
        guest4LastName:   new FormControl(),
        guest4PlusOne:  new FormControl(),
        guest5FirstName:   new FormControl(),
        guest5LastName:   new FormControl(),
        guest5PlusOne:  new FormControl(),
        guest6FirstName:   new FormControl(),
        guest6LastName:   new FormControl(),
        guest6PlusOne:  new FormControl(),
        guest7FirstName:   new FormControl(),
        guest7LastName:   new FormControl(),
        guest7PlusOne:  new FormControl()
      }
    );

    
  }

  public formSubmit()
  {
    let formValue: any;
    let rsvp:      Guest  = new Guest();
    let regex:     RegExp = new RegExp(/\S+@\S+\.\S+/);
    formValue = this.rsvpForm.value;

    if(formValue.firstName == null || formValue.lastName == null)
    {
      alert('Please check form, missing data.')
      return;
    }
  
    if(formValue.plus1FirstName != null && formValue.plus1LastName == null || formValue.plus1FirstName == null && formValue.plus1LastName != null)
    {
      alert('Please check form, missing data.1')
      return;
    }

    if(formValue.guest1FirstName != null && formValue.guest1LastName == null || formValue.guest1FirstName == null && formValue.guest1LastName != null)
    {
      alert('Please check form, missing data.2')
      return;
    }

    if(formValue.guest2FirstName != null && formValue.guest2LastName == null || formValue.guest2FirstName == null && formValue.guest2LastName != null)
    {
      alert('Please check form, missing data.3')
      return;
    }

    if(formValue.guest3FirstName != null && formValue.guest3LastName == null || formValue.guest3FirstName == null && formValue.guest3LastName != null)
    {
      alert('Please check form, missing data.4')
      return;
    }

    if(formValue.guest4FirstName != null && formValue.guest4LastName == null || formValue.guest4FirstName == null && formValue.guest4LastName != null)
    {
      alert('Please check form, missing data.5')
      return;
    }

    
    if(formValue.guest5FirstName != null && formValue.guest5LastName == null || formValue.guest5FirstName == null && formValue.guest5LastName != null)
    {
      alert('Please check form, missing data.6')
      return;
    }

    
    if(formValue.guest6FirstName != null && formValue.guest6LastName == null || formValue.guest6FirstName == null && formValue.guest6LastName != null)
    {
      alert('Please check form, missing data.7')
      return;
    }

    
    if(formValue.guest7FirstName != null && formValue.guest7LastName == null || formValue.guest7FirstName == null && formValue.guest7LastName != null)
    {
      alert('Please check form, missing data.8')
      return;
    }




    rsvp.fname = formValue.firstName;
    rsvp.lname = formValue.lastName;
    
    let relatedPeople: string[] = [];
    let plus1relatedPeople: string[] = [];
    let guest1relatedPeople: string[] = [];
    let guest2relatedPeople: string[] = [];
    let guest3relatedPeople: string[] = [];
    let guest4relatedPeople: string[] = [];
    let guest5relatedPeople: string[] = [];
    let guest6relatedPeople: string[] = [];
    let guest7relatedPeople: string[] = [];
    if(formValue.plus1FirstName != null)
    {
      relatedPeople[0] = formValue.plus1FirstName + ' ' + formValue.plus1LastName;
      plus1relatedPeople[0] = rsvp.fname + ' ' + rsvp.lname;
    }
    if(formValue.guest1FirstName != null)
    {
      let guest1Name: string = formValue.guest1FirstName + ' ' + formValue.guest1LastName;
      relatedPeople[1] = guest1Name;
      plus1relatedPeople[1] = guest1Name;

      guest1relatedPeople[0] = rsvp.fname + ' ' + rsvp.lname;
      guest1relatedPeople[1] = formValue.plus1FirstName + ' ' + formValue.plus1LastName;
    }
    if(formValue.guest2FirstName != null)
    {
      let guest2Name: string = formValue.guest2FirstName + ' ' + formValue.guest2LastName;
      relatedPeople[2] = guest2Name;
      plus1relatedPeople[2] = guest2Name;
      guest1relatedPeople[2] = guest2Name;

      guest2relatedPeople[0] = rsvp.fname + ' ' + rsvp.lname;
      guest2relatedPeople[1] = formValue.plus1FirstName + ' ' + formValue.plus1LastName;
      guest2relatedPeople[2] = formValue.guest1FirstName + ' ' + formValue.guest1LastName;
      
    }
    if(formValue.guest3FirstName != null)
    {
      let guest3Name: string = formValue.guest3FirstName + ' ' + formValue.guest3LastName;
      relatedPeople[3] = guest3Name;
      plus1relatedPeople[3] = guest3Name;
      guest1relatedPeople[3] = guest3Name;
      guest2relatedPeople[3] = guest3Name;

      guest3relatedPeople[0] = rsvp.fname + ' ' + rsvp.lname;
      guest3relatedPeople[1] = formValue.plus1FirstName + ' ' + formValue.plus1LastName;
      guest3relatedPeople[2] = formValue.guest1FirstName + ' ' + formValue.guest1LastName;
      guest3relatedPeople[3] = formValue.guest2FirstName + ' ' + formValue.guest2LastName;
    }
    if(formValue.guest4FirstName != null)
    {
      let guest4Name: string = formValue.guest4FirstName + ' ' + formValue.guest4LastName;
      relatedPeople[4] = guest4Name;
      plus1relatedPeople[4] = guest4Name;
      guest1relatedPeople[4] = guest4Name;
      guest2relatedPeople[4] = guest4Name;
      guest3relatedPeople[4] = guest4Name;

      guest4relatedPeople[0] = rsvp.fname + ' ' + rsvp.lname;
      guest4relatedPeople[1] = formValue.plus1FirstName + ' ' + formValue.plus1LastName;
      guest4relatedPeople[2] = formValue.guest1FirstName + ' ' + formValue.guest1LastName;
      guest4relatedPeople[3] = formValue.guest2FirstName + ' ' + formValue.guest2LastName;
      guest4relatedPeople[4] = formValue.guest3FirstName + ' ' + formValue.guest3LastName;
    }
    if(formValue.guest5FirstName != null)
    {
      let guest5Name: string = formValue.guest5FirstName + ' ' + formValue.guest5LastName;
      relatedPeople[5] = guest5Name;

      plus1relatedPeople[5] = guest5Name;
      guest1relatedPeople[5] = guest5Name;
      guest2relatedPeople[5] = guest5Name;
      guest3relatedPeople[5] = guest5Name;

      guest4relatedPeople[5] = guest5Name;

      guest5relatedPeople[0] = rsvp.fname + ' ' + rsvp.lname;
      guest5relatedPeople[1] = formValue.plus1FirstName + ' ' + formValue.plus1LastName;
      guest5relatedPeople[2] = formValue.guest1FirstName + ' ' + formValue.guest1LastName;
      guest5relatedPeople[3] = formValue.guest2FirstName + ' ' + formValue.guest2LastName;
      guest5relatedPeople[4] = formValue.guest3FirstName + ' ' + formValue.guest3LastName;
      guest5relatedPeople[5] = formValue.guest4FirstName + ' ' + formValue.guest4LastName;
    }
    if(formValue.guest6FirstName != null)
    {
      let guest6Name: string = formValue.guest6FirstName + ' ' + formValue.guest6LastName;
      relatedPeople[6] = guest6Name;
      plus1relatedPeople[6] = guest6Name;
      guest1relatedPeople[6] = guest6Name;
      guest2relatedPeople[6] = guest6Name;
      guest3relatedPeople[6] = guest6Name;
      guest4relatedPeople[6] = guest6Name;
      guest5relatedPeople[6] = guest6Name;

      guest6relatedPeople[0] = rsvp.fname + ' ' + rsvp.lname;
      guest6relatedPeople[1] = formValue.plus1FirstName + ' ' + formValue.plus1LastName;
      guest6relatedPeople[2] = formValue.guest1FirstName + ' ' + formValue.guest1LastName;
      guest6relatedPeople[3] = formValue.guest2FirstName + ' ' + formValue.guest2LastName;
      guest6relatedPeople[4] = formValue.guest3FirstName + ' ' + formValue.guest3LastName;
      guest6relatedPeople[5] = formValue.guest4FirstName + ' ' + formValue.guest4LastName;
      guest6relatedPeople[6] = formValue.guest5FirstName + ' ' + formValue.guest5LastName;
    }
    if(formValue.guest7FirstName != null)
    {
      let guest7Name: string = formValue.guest7FirstName + ' ' + formValue.guest7LastName;
      relatedPeople[7] = guest7Name;
      plus1relatedPeople[7] = guest7Name;
      guest1relatedPeople[7] = guest7Name;
      guest2relatedPeople[7] = guest7Name;
      guest3relatedPeople[7] = guest7Name;
      guest4relatedPeople[7] = guest7Name;
      guest5relatedPeople[7] = guest7Name;
      guest6relatedPeople[7] = guest7Name;

      guest7relatedPeople[0] = rsvp.fname + ' ' + rsvp.lname;
      guest7relatedPeople[1] = formValue.plus1FirstName + ' ' + formValue.plus1LastName;
      guest7relatedPeople[2] = formValue.guest1FirstName + ' ' + formValue.guest1LastName;
      guest7relatedPeople[3] = formValue.guest2FirstName + ' ' + formValue.guest2LastName;
      guest7relatedPeople[4] = formValue.guest3FirstName + ' ' + formValue.guest3LastName;
      guest7relatedPeople[5] = formValue.guest4FirstName + ' ' + formValue.guest4LastName;
      guest7relatedPeople[6] = formValue.guest5FirstName + ' ' + formValue.guest5LastName;
      guest7relatedPeople[7] = formValue.guest6FirstName + ' ' + formValue.guest6LastName;
    }

    if(formValue.firstName != null)
    {
      let guest0: Guest = new Guest();
      guest0.fname = formValue.firstName;
      guest0.lname = formValue.lastName;
      guest0.relatedPeople = relatedPeople;
      if(formValue.hasPlusOne == true)
      {
        guest0.plusOne = true;
      }
      else
      {
        guest0.plusOne = false;
      }
      guest0.vegan = false;
      guest0.glutenFree = false;
      guest0.vegetarian = false;
      console.log(guest0);
      this.rsvpService.createRSVP(guest0);
    }

    if(formValue.plus1FirstName != null)
    {
      let guest: Guest = new Guest();
      guest.fname = formValue.plus1FirstName;
      guest.lname = formValue.plus1LastName;
      guest.relatedPeople = plus1relatedPeople;
        guest.plusOne = false;
      
      guest.vegan = false;
      guest.glutenFree = false;
      guest.vegetarian = false;
      this.rsvpService.createRSVP(guest);
    }
    if(formValue.guest1FirstName != null)
    {
      let guest: Guest = new Guest();
      guest.fname = formValue.guest1FirstName;
      guest.lname = formValue.guest1LastName;
      guest.relatedPeople = guest1relatedPeople;
      if(formValue.guest1PlusOne == true)
      {
        guest.plusOne = true;
      }
      else
      {
        guest.plusOne = false;
      }
      guest.vegan = false;
      guest.glutenFree = false;
      guest.vegetarian = false;
      this.rsvpService.createRSVP(guest);
    }
    if(formValue.guest2FirstName != null)
    {
      let guest: Guest = new Guest();
      guest.fname = formValue.guest2FirstName;
      guest.lname = formValue.guest2LastName;
      guest.relatedPeople = guest2relatedPeople;
      if(formValue.guest2PlusOne == true)
      {
        guest.plusOne = true;
      }
      else
      {
        guest.plusOne = false;
      }
      guest.vegan = false;
      guest.glutenFree = false;
      guest.vegetarian = false;
      this.rsvpService.createRSVP(guest);
      
    }
    if(formValue.guest3FirstName != null)
    {
      let guest: Guest = new Guest();
      guest.fname = formValue.guest3FirstName;
      guest.lname = formValue.guest3LastName;
      guest.relatedPeople = guest3relatedPeople;
      if(formValue.guest3PlusOne == true)
      {
        guest.plusOne = true;
      }
      else
      {
        guest.plusOne = false;
      }
      guest.vegan = false;
      guest.glutenFree = false;
      guest.vegetarian = false;
      this.rsvpService.createRSVP(guest);
    }
    if(formValue.guest4FirstName != null)
    {
      let guest: Guest = new Guest();
      guest.fname = formValue.guest4FirstName;
      guest.lname = formValue.guest4LastName;
      guest.relatedPeople = guest4relatedPeople;
      if(formValue.guest4PlusOne == true)
      {
        guest.plusOne = true;
      }
      else
      {
        guest.plusOne = false;
      }
      guest.vegan = false;
      guest.glutenFree = false;
      guest.vegetarian = false;
      this.rsvpService.createRSVP(guest);
    }
    if(formValue.guest5FirstName != null)
    {
      let guest: Guest = new Guest();
      guest.fname = formValue.guest5FirstName;
      guest.lname = formValue.guest5LastName;
      guest.relatedPeople = guest5relatedPeople;
      if(formValue.guest5PlusOne == true)
      {
        guest.plusOne = true;
      }
      else
      {
        guest.plusOne = false;
      }
      guest.vegan = false;
      guest.glutenFree = false;
      guest.vegetarian = false;
      this.rsvpService.createRSVP(guest);
    }
    if(formValue.guest6FirstName != null)
    {
      let guest: Guest = new Guest();
      guest.fname = formValue.guest6FirstName;
      guest.lname = formValue.guest6LastName;
      guest.relatedPeople = guest6relatedPeople;
      if(formValue.guest6PlusOne == true)
      {
        guest.plusOne = true;
      }
      else
      {
        guest.plusOne = false;
      }
      guest.vegan = false;
      guest.glutenFree = false;
      guest.vegetarian = false;
      this.rsvpService.createRSVP(guest);
    }
    if(formValue.guest7FirstName != null)
    {
      let guest: Guest = new Guest();
      guest.fname = formValue.guest7FirstName;
      guest.lname = formValue.guest7LastName;
      guest.relatedPeople = guest7relatedPeople;
      if(formValue.guest7PlusOne == true)
      {
        guest.plusOne = true;
      }
      else
      {
        guest.plusOne = false;
      }
      guest.vegan = false;
      guest.glutenFree = false;
      guest.vegetarian = false;
      this.rsvpService.createRSVP(guest);
    }
  }
}