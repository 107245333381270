import { Component, OnInit, OnChanges, HostListener } from '@angular/core';
import { AppStateService } from './app-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit
{
  public isMobile: boolean;

  constructor(private appStateService: AppStateService)
  {
    this.isMobile = appStateService.getIsMobile();
  }

  ngOnInit()
  {
    this.isMobile = this.appStateService.getIsMobile();
  }

  @HostListener('window:resize')
  onResize()
  {
    this.isMobile = this.appStateService.getIsMobile();
  }
}
