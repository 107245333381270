import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.scss']
})
export class RegistryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  public openRegistry()
  {
    window.open('https://www.amazon.com/wedding/nicholas-hicks-rachel-santos--july-2021/registry/34DVANMTNLF2S', "_blank")
  }
}
