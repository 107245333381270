import { Component, OnInit } from '@angular/core';
import { Person } from '../models/Person.model';
import { RsvpServiceService } from '../services/rsvp-service.service';

@Component({
  selector: 'app-attendees',
  templateUrl: './attendees.component.html',
  styleUrls: ['./attendees.component.scss']
})
export class AttendeesComponent implements OnInit {

  constructor(private rsvpService: RsvpServiceService) { }

  public peopleCount: number = 0;
  public guests:      Person[] = [];

  ngOnInit() 
  {
    this.rsvpService.getAllAttendees().then((people: Person[]) =>
    {
      this.peopleCount = people.length;
      this.guests = people;
      people.forEach((person: Person) =>
      {
        if(person.isBringingPlusOne)
        {
          this.peopleCount++;
        }
      });
    });
  }

}
