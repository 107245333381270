import { Component, OnInit } from '@angular/core';
import { AppStateService } from '../app-state.service';

@Component({
  selector: 'app-wedding-details',
  templateUrl: './wedding-details.component.html',
  styleUrls: ['./wedding-details.component.scss']
})
export class WeddingDetailsComponent implements OnInit {
  lat = 40.2043414;
  lng = -79.4797663;

  public isMobile: boolean;

  constructor(private appStateService: AppStateService)
  {
    this.isMobile = appStateService.getIsMobile();
  }

  ngOnInit()
  {
    this.isMobile = this.appStateService.getIsMobile();
  }

}
