// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAzhs_Z9NZCxUx562KgDC5nkdaQXr6sQ94",
    authDomain: "wedding-app-277401.firebaseapp.com",
    databaseURL: "https://wedding-app-277401.firebaseio.com",
    projectId: "wedding-app-277401",
    storageBucket: "wedding-app-277401.appspot.com",
    messagingSenderId: "725830809685",
    appId: "1:725830809685:web:beadcf568f0ce1fc65bcdc",
    measurementId: "G-KEG5RF5JH5"
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
